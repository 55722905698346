/** @format */

import React from "react";
import CountUp from "react-countup";

const Growth = () => {
  return (
    <div className='rn-counterup-area ptb--120 bg_color--5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='section-title text-center'>
              <span className='subtitle'>Experts growths</span>
              <h2 className='title'>Our Growth</h2>
              <p className='description'>
                We have grown strength over the past 6 years.
              </p>
            </div>
          </div>
        </div>
        <div className='row mt--30'>
          <div className='im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12'>
            <div className='im_counterup' data-aos='zoom-in'>
              <div className='inner'>
                <div className='icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-heart'>
                    <path d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z' />
                  </svg>
                </div>
                <h2 className='counter'>
                  <span>
                    <CountUp delay={3} start={100} end={199} />
                  </span>
                </h2>
                <p className='description'>Staticfied Customers</p>
              </div>
            </div>
          </div>
          <div className='im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12'>
            <div className='im_counterup' data-aos='zoom-out'>
              <div className='inner'>
                <div className='icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-clock'>
                    <circle cx={12} cy={12} r={10} />
                    <polyline points='12 6 12 12 16 14' />
                  </svg>
                </div>
                <h2 className='counter'>
                  <span>
                    <CountUp
                      delay={3}
                      enableScrollSpy={true}
                      start={1000}
                      end={1500}
                    />
                  </span>
                </h2>
                <p className='description'>Days Of Operation</p>
              </div>
            </div>
          </div>
          <div className='im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12'>
            <div className='im_counterup' data-aos='zoom-in'>
              <div className='inner'>
                <div className='icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-stop-circle'>
                    <circle cx={12} cy={12} r={10} />
                    <rect x={9} y={9} width={6} height={6} />
                  </svg>
                </div>
                <h2 className='counter'>
                  <span>
                    <CountUp delay={3} start={500} end={1000} />
                  </span>
                </h2>
                <p className='description'>Completed Project</p>
              </div>
            </div>
          </div>
          <div className='im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12'>
            <div className='im_counterup' data-aos='zoom-out'>
              <div className='inner'>
                <div className='icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='feather feather-award'>
                    <circle cx={12} cy={8} r={7} />
                    <polyline points='8.21 13.89 7 23 12 20 17 23 15.79 13.88' />
                  </svg>
                </div>
                <h2 className='counter'>
                  <span>
                    <CountUp
                      delay={3}
                      decimals={1}
                      decimal='.'
                      enableScrollSpy={true}
                      start={1.5}
                      end={4.5}
                    />
                  </span>
                </h2>
                <p className='description'>Client Reviews</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Growth;
