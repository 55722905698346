/** @format */

import React from "react";
import { FaWhatsapp, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className='footer-area footer-style-01 bg_color--6' id='contact'>
      <div className='im-call-to-action-area ptb--70 im-separator'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12'>
              <div className='inner'>
                <h2 className='text-white mb--0'>
                  Let's make magic together!{" "}
                </h2>
              </div>
            </div>
            <div className='col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12'>
              <div className='call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20'>
                <a
                  className='btn-default btn-large btn-border btn-opacity'
                  href='https://wa.me/46728686235'
                  target='_blank'
                  rel='noreferrer'>
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-wrapper pt-5 pb-4'>
        <div className='container'>
          <div className='row '>
            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
              <div className='ft-text'>
                <Link to='/' className=' logo  '>
                  <img src='assets/logo.png' alt='Digital Agency' />{" "}
                  {/* <span>Bilal Akram</span> */}
                </Link>
                <p>
                  Copyright © 2024 <a href='/'>Bilal Akram</a>, All rights
                  reserved.
                </p>
              </div>
            </div>
            <div className='  col-sm-2  col-6 offset-xl-1    mt_mobile--40'>
              <div className='footer-link'>
                <h4>Quick Link</h4>
                <ul className='ft-link'>
                  <li>
                    <a href='#'>Home</a>
                  </li>
                  <li>
                    <a href='#watch'>Watch</a>
                  </li>

                  <li>
                    <a href='#!'>Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='  col-sm-2   col-6 mt_md--40 mt_sm--40'>
              <div className='footer-link'>
                <h4>Portfolio</h4>
                <ul className='ft-link'>
                  <li>
                    <Link to='/vlogs'>Vlogs</Link>
                  </li>
                  <li>
                    <Link to='/short-ads'>Short Ads</Link>
                  </li>
                  <li>
                    <Link to='/reels'>Reels</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40'>
              <div className='footer-link'>
                <h4>Say Hello</h4>
                <ul className='ft-link'>
                  <li>
                    Email:{" "}
                    <a href='mailto:bilalakram49@gmail.com'>
                      bilalakram49@gmail.com
                    </a>
                  </li>
                  <li>
                    Phone: <a href='tel:+46728686235'>+46 72 868 62 35</a>
                  </li>
                </ul>
                <div className='social-share-inner mt--20'>
                  <ul className='social-share social-style--2 d-flex justify-content-start liststyle'>
                    <li>
                      <a
                        href='https://twitter.com/bilalaakram'
                        target='_blank'
                        rel='noreferrer'>
                        <FaTwitter className='fab fa-facebook-f' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://wa.me/46728686235'
                        target='_blank'
                        rel='noreferrer'>
                        <FaWhatsapp className='fab fa-linkedin-in' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.instagram.com/bilal.aakram/'
                        target='_blank'
                        rel='noreferrer'>
                        <FaInstagram className='fab fa-twitter' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.linkedin.com/in/bilalaakram/'
                        target='_blank'
                        rel='noreferrer'>
                        <FaLinkedin className='fab fa-instagram' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='row mt-4 '>
            <a
              href='https://mehassan.netlify.app/'
              target='_blank'
              rel='noreferrer'
              className=' text-white d-flex opacity-75 text-center justify-content-center'>
              Develop By:{" "}
              <img
                src='https://mehassan.netlify.app/assets/logo.png'
                className=' d-inline-block'
                width={150}
                alt=''
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
