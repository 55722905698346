/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className='header-area formobile-menu header--transparent default-color'>
        <div className='header-wrapper' id='header-wrapper'>
          <div className='header-left'>
            <div>
              <Link
                to='/'
                className=' logo m-auto  d-flex  align-items-center mx-3'>
                <img src='assets/logo.png' alt='Digital Agency' />{" "}
                {/* <span>Bilal Akram</span> */}
              </Link>
            </div>
          </div>
          <div className='header-right m-auto'>
            <nav className='mainmenunav '>
              <ul className='mainmenu'>
                <li className='has-dropdown'>
                  <Link to='/'>Home</Link>
                </li>

                <li className='has-dropdown'>
                  <a href='#watch'>Watch </a>
                </li>
                <li className='has-dropdown'>
                  <a href='#contact'>Contact</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='header-btn'>
            <a
              className='btn-default btn-border btn-opacity'
              rel='noreferrer'
              target='_blank'
              href='https://wa.me/46728686235'>
              <span>Order Now</span>
            </a>
          </div>
        </div>
      </header>
      {/* <div className='rn-popup-mobile-menu d-block d-lg-none'>
        <div className='inner'>
          <div className='popup-menu-top'>
          <Link
                to='/'
                className=' logo m-auto  d-flex  align-items-center '>
             
              </Link>
            <div className='close-menu d-block d-lg-none'>
              <span className='closeTrigger'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='feather feather-x'>
                  <line x1={18} y1={6} x2={6} y2={18} />
                  <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
              </span>
            </div>
          </div>
          <ul className='mainmenu'>
            <li className='has-dropdown'>
              <Link to='/'>Home</Link>
            </li>

            <li className='has-dropdown'>
              <a href='#!'>Watch </a>
            </li>
            <li>
              <a href='#!'>Contact</a>
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
};

export default Header;
