/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
const About = () => {
  return (
    <div className='about-area ptb--120'>
      <div className='about-wrapper' id='about'>
        <div className='container'>
          <div className='row row--35 align-items-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='thumbnail' data-aos='fade-right'>
                <img className='w-100' src='assets/ME.png' alt='About Images' />
              </div>
            </div>
            <div className='col-lg-7 col-md-12'>
              <div className='about-inner inner'>
                <div className='section-title'>
                  <a
                    href='https://wa.me/46728686235'
                    target='_blank'
                    rel='noreferrer'>
                    <div className='icon'>
                      {/* <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-send'>
                        <line x1={22} y1={2} x2={11} y2={13} />
                        <polygon points='22 2 15 22 11 13 2 9 22 2' />
                      </svg> */}
                      <FaWhatsapp className='feather ' />
                    </div>
                  </a>
                  <h2 className='title'>Welcome to my portfolio!</h2>
                  <p className='description'>
                    I'm <strong>Bilal Akram</strong>, a seasoned Visual Content
                    Producer with a proven track record of delivering over <strong>1,000 </strong>
                    successful projects. From captivating documentaries to
                    engaging advertisements, I bring a blend of creativity and
                    precision to every piece of content I produce.
                  </p>
                  <p className='description'>
                    Let's collaborate to create visually stunning content that
                    leaves a lasting impact on your audience. Elevate your brand
                    with exceptional visual storytelling, and together, we can
                    create something truly remarkable!
                  </p>
                  <div className='purchase-btn'>
                    <Link className='btn-transparent' to='/'>
                      Bilal Akram{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
