/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";

const Ads = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  const backToHome = () => {
    window.history.back();
  };
  const videoData = [
    {
      title: "Coptrz",
      description: "Welcome to COPTRZ Academy",
      posterUrl: "https://i.ytimg.com/vi/X3xD-_pJ2hc/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/X3xD-_pJ2hc?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/CJLuXPdCt3E/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/CJLuXPdCt3E?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/upaZm6uJNoQ/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/upaZm6uJNoQ?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/V0ao0taykR0/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/V0ao0taykR0?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/a-VXQhvSEZ8/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/a-VXQhvSEZ8?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "",
      description: "",
      posterUrl: "https://i.ytimg.com/vi/_DoUv0YmULI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/_DoUv0YmULI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
  ];

  return (
    <>
      <div className='container-fluid sec-bg2 pt-5'>
        <div className='carousel-container'>
          <div className='carousel'>
            <div className='slider  '>
              <div className='slide-content '>
                <h1 className='movie-title '>Short Ads</h1>
                <div className='header-btn   backIcon'>
                  <a
                    onClick={backToHome}
                    className='btn-default btn-border btn-opacity'>
                    <BsArrowLeft className='fs-4 text-light mx-2' />
                    <span>Back to Home page</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* cards */}
      <div className='container-fluid mb-5 pb-5'>
        <div className='row  '>
          {videoData.map((item, index) => {
            return (
              <div className='col-lg-4 ' key={index}>
                <span
                  id='single_image'
                  data-fancybox
                  data-type='iframe'
                  data-src={item.trailerUrl}>
                  <div
                    className='movie-card'
                    style={{
                      backgroundImage: `url(${item.posterUrl})`,
                    }}>
                    <button
                      className='btn btn-outline movie-card__button'
                      type='button'>
                      <FaPlay className='btn-icon' /> Watch Now
                    </button>
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Ads;
