/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import { useEffect } from "react";

const Business = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    scrollToTop();
  }, []);
  const backToHome = () => {
    window.history.back();
  };
  const videoData = [
    {
      title: "ItsKeaton",
      description: "My 7 Essential Softwares for SMMA Success",
      posterUrl: "https://i.ytimg.com/vi/uFEsYB33GVk/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/uFEsYB33GVk?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Moran Pober",
      description: "Must Know Employee Lessons in Acquisition Rollups!",
      posterUrl: "https://i.ytimg.com/vi/Teo4sg2x7-0/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/IZCfP13twxk?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "FlashFilm Academy",
      description: "5 Perfect Gifts Every Videographer or Filmmaker Will Love",
      posterUrl: "https://i.ytimg.com/vi/jSTavhzOJIc/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/jSTavhzOJIc?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "FlashFilm Academy",
      description:
        "The Truth About Komodo Reviews: Why You Should NEVER Trust Most of Them on YouTube!",
      posterUrl: "https://i.ytimg.com/vi/z0EUnfYmqwQ/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/IZCfP13twxk?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Coptrz",
      description:
        "How drones are improving English Refereeing - PGMOL Case Study",
      posterUrl: "https://i.ytimg.com/vi/ONLY0mmT9uA/hqdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/ONLY0mmT9uA?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    //   {
    //   title: "",
    //   description: "",
    //   posterUrl: "",
    //   trailerUrl: "https://www.youtube.com/embed/IZCfP13twxk?autoplay=1&rel=0&controls=0&showinfo=0",
    // },
  ];

  return (
    <>
      <div className='container-fluid sec-bg2 pt-5'>
        <div className='carousel-container'>
          <div className='carousel'>
            <div className='slider  '>
              <div className='slide-content '>
                <h1 className='movie-title '>Business</h1>
                <div className='header-btn   backIcon'>
                <a
                    onClick={backToHome}
                    className='btn-default btn-border btn-opacity'>
                    <BsArrowLeft className='fs-4 text-light mx-2' />
                    <span>Back to Home page</span>
                  </a>
              </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
      {/* cards */}
      <div className='container-fluid mb-5 pb-5'>
        <div className='row  '>
          {videoData.map((item, index) => {
            return (
              <div className='col-lg-4' key={index}>
                <span
                  id='single_image'
                  data-fancybox
                  data-type='iframe'
                  data-src={item.trailerUrl}>
                  <div
                    className='movie-card'
                    style={{
                      backgroundImage: `url(${item.posterUrl})`,
                    }}>
                    <button
                      className='btn btn-outline movie-card__button'
                      type='button'>
                      <FaPlay className='btn-icon' /> Watch Now
                    </button>
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Business;
