/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";

const Vlogs = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    scrollToTop();
  }, []);
  const backToHome = () => {
    window.history.back();
  };
  const videoData = [
    {
      title: "Trevin Peterson",
      description: "9 Secrets to Succeed with Amazon FBA (Golf Edition)",
      posterUrl: "https://i.ytimg.com/vi/hKu_Nk_xOIw/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/hKu_Nk_xOIw?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Seancutshair",
      description: "2 Years NO Haircut! 😬",
      posterUrl: "https://i.ytimg.com/vi/2w7jGbGyKLo/hqdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/2w7jGbGyKLo?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Escape To Arizona Ft.RyantheRealtor",
      description:
        "Power Ranch! Easter Event | What's an HOA Event Like? | Living In Gilbert",
      posterUrl: "https://i.ytimg.com/vi/9Kh8-QBtcW4/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/9Kh8-QBtcW4?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Cheryl Burke",
      description: "The Making of the Cheryl Burke Loungewear Line",
      posterUrl: "https://i.ytimg.com/vi/j31oHcYR7ew/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/IZCfP13twxk?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Traveling with Kristin",
      description: "How I See the UK as an American Abroad [CULTURE SHOCK]",
      posterUrl: "https://i.ytimg.com/vi/qaZmoCPLCs8/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/qaZmoCPLCs8?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Hallden",
      description: "STARTUP | Ep. 2",
      posterUrl: "https://i.ytimg.com/vi/KDYLPiSuOug/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/KDYLPiSuOug?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Bitget",
      description: "DOTA 2 Bali Major EPIC Vlog | Bitget Travel Series",
      posterUrl: "https://i.ytimg.com/vi/SLtL4uGxrQc/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/SLtL4uGxrQc?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Bitget",
      description:
        "Bitget X MESSI Paint The Streets Of Buenos Aires In Argentina",
      posterUrl: "https://i.ytimg.com/vi/bqY4ubEedcw/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/bqY4ubEedcw?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Bitget",
      description: "UCL Final Match 2023 Vlog 🏆 | Bitget Travel Series",
      posterUrl: "https://i.ytimg.com/vi/_7DPWukUWr8/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/_7DPWukUWr8?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Michael Krajewski",
      description: "The Business Show 2021 (Review)",
      posterUrl: "https://i.ytimg.com/vi/_9mMs6sduFA/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/_9mMs6sduFA?autoplay=1&rel=0&controls=0&showinfo=0",
    },
  ];

  return (
    <>
      <div className='container-fluid sec-bg2 pt-5  '>
        <div className='carousel-container'>
          <div className='carousel'>
            <div className='slider  '>
              <div className='slide-content '>
                <h1 className='movie-title '>Vlogs</h1>
                <div className='header-btn   backIcon'>
                <a
                    onClick={backToHome}
                    className='btn-default btn-border btn-opacity'>
                    <BsArrowLeft className='fs-4 text-light mx-2' />
                    <span>Back to Home page</span>
                  </a>
              </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
      {/* cards */}
      <div className='container-fluid mb-5 pb-5'>
        <div className='row  '>
          {videoData.map((item, index) => {
            return (
              <div className='col-lg-4' key={index}>
                <span
                  id='single_image'
                  data-fancybox
                  data-type='iframe'
                  data-src={item.trailerUrl}>
                  <div
                    className='movie-card'
                    style={{
                      backgroundImage: `url(${item.posterUrl})`,
                    }}>
                    <button
                      className='btn btn-outline movie-card__button'
                      type='button'>
                      <FaPlay className='btn-icon' /> Watch Now
                    </button>
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Vlogs;
