/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";

const Talk = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  const backToHome = () => {
    window.history.back();
  };
  const videoData = [
    {
      title: "CourseRanks",
      description:
        "Scott Hilse's FREE Dropshipping Course (Was $500!) Worth It?",
      posterUrl: "https://i.ytimg.com/vi/uEgPeSf8zb0/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/uEgPeSf8zb0?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Tech With Soleyman",
      description: "How I Learned To Code in 3 Months and Got a Job",
      posterUrl: "https://i.ytimg.com/vi/D7Hv2bMhZ5g/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/D7Hv2bMhZ5g?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Tech With Soleyman",
      description: "How I Passed My AWS Cloud Practitioner Exam in 7 Days",
      posterUrl: "https://i.ytimg.com/vi/s8byh4MCJYw/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/s8byh4MCJYw?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Tech With Soleyman",
      description:
        "From Beginner to Software Engineer | My Full Programming Journey",
      posterUrl: "https://i.ytimg.com/vi/2dMNvjpEu_o/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/2dMNvjpEu_o?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Trevin Peterson",
      description: "Andrew Tate - The DARK TRUTH of Hustler's University 2.0",
      posterUrl: "https://i.ytimg.com/vi/xrOERswzyKI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/xrOERswzyKI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Zach Ginn",
      description:
        "How to Overcome the Objection of Being a New or Young Wholesaler | Wholesaling Real Estate",
      posterUrl: "https://i.ytimg.com/vi/6mZ8T2Vwha8/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/6mZ8T2Vwha8?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Aaron Jack",
      description: "Avoid These HTML & CSS Mistakes At All Costs",
      posterUrl: "https://i.ytimg.com/vi/8FlWUa04f2g/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/8FlWUa04f2g?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Aaron Abke",
      description:
        "The Science of Mystical Experiences // Spiritual Intelligence 05",
      posterUrl: "https://i.ytimg.com/vi/95Xxtc8C6xY/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/95Xxtc8C6xY?autoplay=1&rel=0&controls=0&showinfo=0",
    },
    {
      title: "Frederique Bros",
      description: "How To Pack 21 OUTFITS in ONE Suitcase",
      posterUrl: "https://i.ytimg.com/vi/I1k4t2ZTAqI/maxresdefault.jpg",
      trailerUrl:
        "https://www.youtube.com/embed/I1k4t2ZTAqI?autoplay=1&rel=0&controls=0&showinfo=0",
    },
  ];

  return (
    <>
      <div className='container-fluid sec-bg2 pt-5'>
        <div className='carousel-container'>
          <div className='carousel'>
            <div className='slider  '>
              <div className='slide-content '>
                <h1 className='movie-title '>Talking Head</h1>
                <div className='header-btn   backIcon'>
                  <a
                    onClick={backToHome}
                    className='btn-default btn-border btn-opacity'>
                    <BsArrowLeft className='fs-4 text-light mx-2' />
                    <span>Back to Home page</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* cards */}
      <div className='container-fluid mb-5 pb-5'>
        <div className='row  '>
          {videoData.map((item, index) => {
            return (
              <div className='col-lg-4 ' key={index}>
                <span
                  id='single_image'
                  data-fancybox
                  data-type='iframe'
                  data-src={item.trailerUrl}>
                  <div
                    className='movie-card'
                    style={{
                      backgroundImage: `url(${item.posterUrl})`,
                    }}>
                    <button
                      className='btn btn-outline movie-card__button'
                      type='button'>
                      <FaPlay className='btn-icon' /> Watch Now
                    </button>
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Talk;
