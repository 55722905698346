/** @format */

import React from "react";
// import { useEffect } from "react";
import Typewriter from "typewriter-effect";
const Hero = () => {
  // useEffect(() => {
  //   const scrollToTop = () => {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  //   };

  //   scrollToTop();
  // }, []);
  return (
    <div className='slider-wrapper'>
      {/* <video playsInline autoPlay muted loop>
        <source
          src={process.env.REACT_APP_S3_URL + "/DURS4ZQ.mp4"}
          type='video/mp4'
        />
        Your browser does not support the video tag.
      </video> */}
      <div className='slider-activation'>
        <div
          className='slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1'
          data-black-overlay={8}>
          <div className='container position-relative'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner'>
                  <h1 className='title '>
                    <Typewriter
                      options={{
                        strings: [
                          "Video Editor",
                          "Motion Graphics",
                          "Sound Designer",
                          "Colorist",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </h1>
                  <br />
                  <br />
                  {/* <div className='header-btn' data-aos='fade-up'>
                    <a className='btn-default btn btn-opacity'>
                      <span>Contact Me</span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='service-wrapper service-white'>
              <div className='row row--25'>
                <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                  <div className='service service__style--1'>
                    <div className='icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-activity'>
                        <polyline points='22 12 18 12 15 21 9 3 6 12 2 12' />
                      </svg>
                    </div>
                    <div className='content'>
                      <h4 className='title'>Awarded Design</h4>
                      <p>
                        There are many variations variations of passages of
                        Lorem Ipsum available.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                  <div className='service service__style--1'>
                    <div className='icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-cast'>
                        <path d='M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6' />
                        <line x1={2} y1={20} x2='2.01' y2={20} />
                      </svg>
                    </div>
                    <div className='content'>
                      <h4 className='title'>Design &amp; Creative</h4>
                      <p>
                        Passages there are many variations variations of of
                        Lorem Ipsum available.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                  <div className='service service__style--1'>
                    <div className='icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-map'>
                        <polygon points='1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6' />
                        <line x1={8} y1={2} x2={8} y2={18} />
                        <line x1={16} y1={6} x2={16} y2={22} />
                      </svg>
                    </div>
                    <div className='content'>
                      <h4 className='title'>App Development</h4>
                      <p>
                        Variations There are many variations of passages of
                        Lorem Ipsum available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
    </div>
  );
};

export default Hero;
