/** @format */

import React from "react";
import Hero from "../components/elements/Hero";
import About from "../components/elements/About";
import Portfolio from "../components/elements/Portfolio";
import Growth from "../components/elements/Growth";
import Clients from "../components/elements/Clients";

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <About />
      {/* <Services /> */}
      <Portfolio />
      <Growth />
      <Clients />
    </React.Fragment>
  );
};

export default Home;
